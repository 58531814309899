<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { apiPathUrl } from "@/Utils/Helpers";
import { useForm } from "@inertiajs/vue3";
import { toast } from "vue-sonner";
import { isEmpty } from "lodash-es";
import AppForm from "@/Components/Shared/forms/AppForm.vue";
import AppHeader from "@/Components/Shared/header/AppHeader.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppFormGroup from "@/Components/Shared/forms/AppFormGroup.vue";
import AppFormFooter from "@/Components/Shared/forms/AppFormFooter.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppFormInputText from "@/Components/Shared/forms/AppFormInputText.vue";
import AppCardRow from "@/Components/Shared/card/AppCardRow.vue";
import AppContainer from "@/Components/Shared/container/AppContainer.vue";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";
import AppFormInputTextarea from "@/Components/Shared/forms/AppFormInputTextarea.vue";
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";

type Props = {
    cardId: string;
};
const { cardId } = defineProps<Props>();

const loading = ref(false);
const card = ref(null);
const cardNewVariant = ref(null);
const changeVariant = ref(false);

onMounted(() => {
    getWishlistData();
});
const getWishlistData = () => {
    loading.value = true;
    axios
        .get(apiPathUrl("/wishlists/" + cardId + "/get"))
        .then((res: { data: any }) => {
            form.quantity = res.data.wishlist.quantity;
            form.description = res.data.wishlist.description;

            if (res.data.wishlist.card) {
                card.value = res.data.wishlist.card;
            }
        })
        .finally(() => {
            loading.value = false;
        });
};

const emit = defineEmits(["close", "success"]);
const form = useForm({
    _method: "PATCH",
    card: cardId,
    quantity: 1,
    description: null,
});

// computed
const goodToGo = computed(() => {
    return !form.processing;
});

const handleSubmit = () => {
    form.post(apiPathUrl(`/wishlists/${cardId}`), {
        preserveScroll: true,
        only: ["card", "wishlists", "errors"],
        onError: () => {
            // console.log(error);
            toast.error(
                "Er ging iets mis bij het wijzigen van het verlanglijstje.",
            );
        },
        onSuccess: (e: any) => {
            const parsed = JSON.parse(JSON.stringify(e));
            if (isEmpty(parsed.props.errors)) {
                emit("success");
                toast.success("Verlanglijstje gewijzigd.");
            }
        },
    });
};
</script>

<template>
    <AppLoader v-if="loading" />
    <AppForm
        v-else
        @submit.prevent="handleSubmit"
    >
        <AppContainer size="small">
            <div v-if="cardNewVariant" class="flex flex-col  mb-4">
                <div class="relative">
                    <AppCardRow
                        :card="cardNewVariant"
                        show-info
                        class="w-full border border-slate-300 ring-green-700/10 ring-4"
                    />

                    <AppButton
                        class="absolute top-0 right-0 -mt-2 -mr-2"
                        icon="cross"
                        variant="round"
                        @click="
                            cardNewVariant = null;
                            form.card = cardId;
                        "
                    />
                </div>

                <AppFormInputError
                    v-if="form.errors"
                    :errors="form.errors.card"
                />
            </div>
            <AppCardRow
                v-else-if="card"
                :card="card"
                show-info
                class="mb-4 w-full border border-slate-300"
            />

            <AppFormGroup>
                <AppFormInputText
                    v-model="form.quantity"
                    name="quantity"
                    label="Aantal"
                    :errors="form.errors.quantity"
                />
                <AppFormInputTextarea
                    v-model="form.description"
                    name="description"
                    label="Beschrijving"
                    :errors="form.errors.description"
                    :min-height="100"
                />
            </AppFormGroup>

            <div
                v-if="
                    card &&
                    card.similar_cards_by_name &&
                    card.similar_cards_by_name.data &&
                    !changeVariant
                "
                class="grow mt-6"
            >
                <AppButton
                    :size="['small', 'full']"
                    color="grayLight"
                    type="button"
                    icon="edit"
                    @click="changeVariant = !changeVariant"
                >
                    Wijzig variant
                </AppButton>
            </div>
        </AppContainer>

        <section
            v-if="changeVariant && card"
            class="relative"
        >
            <AppHeader size="small">
                <AppTitle
                    as="h3"
                    variant="main"
                >
                    Varianten
                </AppTitle>
            </AppHeader>

            <div
                class="grid gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-4 bg-slate-100 p-2 rounded"
            >
                <AppCardRow
                    v-for="cardVariant in card.similar_cards_by_name.data"
                    :key="cardVariant.id"
                    :card="cardVariant"
                    show-info
                    clickable
                    @click="
                        form.card = cardVariant.id;
                        changeVariant = false;
                        cardNewVariant = cardVariant;
                    "
                />
            </div>

            <AppButton
                class="mt-2"
                :size="['small', 'full']"
                icon="edit"
                color="dark"
                type="button"
                @click="changeVariant = !changeVariant"
            >
                Variant niet wijzigen
            </AppButton>
        </section>

        <AppFormFooter>
            <AppButtonGroup variant="spaced">
                <AppButton @click="emit('close')"> Annuleren </AppButton>

                <AppButton
                    color="primary"
                    type="submit"
                    :disabled="!goodToGo"
                >
                    Wijzig
                </AppButton>
            </AppButtonGroup>
        </AppFormFooter>
    </AppForm>
</template>
